<template>
  <div class="c-container">
    <div class="flex flex-col md:flex-row gap-6 justify-center items-center mb-8 w-full">
      <Button
        label="Personal Information"
        :kind="settingIndex === 0 ? 'primary' : 'link'"
        @clicked="settingIndex = 0"
      />

      <Button
        v-if="false"
        label="Billing Information"
        :kind="settingIndex === 1 ? 'primary' : 'link'"
        @clicked="settingIndex = 1"
      />

      <Button label="Social Media" :kind="settingIndex === 2 ? 'primary' : 'link'" @clicked="settingIndex = 2" />

      <Button label="Organization" :kind="settingIndex === 3 ? 'primary' : 'link'" @clicked="settingIndex = 3" />
    </div>

    <div class="c-container c-container--medium">
      <Tile>
        <div v-if="settingIndex === 0">
          <Title align="center" class="mt-4" title="Personal Information" size="medium" />

          <div v-if="false" class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText ref="input" label="Full name" :value="streamerInfo.fullname" @onChange="setFullName" />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText ref="input" label="Contact email" :value="streamerInfo.email" @onChange="setEmail" />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <AutoComplete
              :data="countriesList"
              label="Country"
              :value="streamerInfo.info.country"
              @selectedItem="setCountry"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <AutoComplete
              :data="languageList"
              label="Broadcasting language"
              :value="streamerInfo.info.broadcastingLanguage"
              @selectedItem="setBroadcastingLanguage"
            />
          </div>
        </div>

        <div v-if="settingIndex === 1">
          <div class="flex flex-col md:flex-row content-center pt-4 pr-6 pl-6">
            <Title class="w-fit" title="Billing Information" size="medium" />

            <div class="flex gap-4 md:ml-auto">
              <Button
                kind="link"
                :class="billingType === 0 ? 'active-tab' : ''"
                label="Personal"
                @clicked="billingType = 0"
              />

              <Button
                kind="link"
                :class="billingType === 1 ? 'active-tab' : ''"
                label="Company"
                @clicked="billingType = 1"
              />
            </div>
          </div>

          <div v-if="false && billingType === 0">
            <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
              <InputText ref="input" label="Full name" :value="streamerInfo.fullname" @onChange="setFullName" />
            </div>

            <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
              <InputText ref="input" label="Address" :value="streamerInfo.info.address" @onChange="setAddress" />
            </div>

            <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
              <InputText ref="input" label="City" :value="streamerInfo.info.city" @onChange="setCity" />
            </div>

            <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
              <InputText
                ref="input"
                label="Postal code"
                :value="streamerInfo.info.postalCode"
                @onChange="setPostalCode"
              />
            </div>

            <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
              <AutoComplete
                :data="countriesList"
                label="Country"
                :value="streamerInfo.info.country"
                @selectedItem="setCountry"
              />
            </div>

            <Title class="flex pt-6 pr-6 pb-4 pl-6 w-full" title="Bank account Information" size="small" />

            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
                <InputText ref="input" label="Bank IBAN" :value="streamerInfo.info.banking.iban" @onChange="setIban" />
              </div>

              <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
                <InputText
                  ref="input"
                  label="Bank BIC/SWIFT"
                  :value="streamerInfo.info.banking.bic"
                  @onChange="setBic"
                />
              </div>
            </div>

            <Title class="flex pt-6 pr-6 pb-4 pl-6 w-full" size="small" title="Identity" />

            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="flex flex-col pt-4 pr-6 pb-4 pl-6">
                <div class="flex items-center mb-4 w-full">
                  <span class="input__label">Type</span>
                </div>

                <div class="flex items-center">
                  <select v-model="streamerInfo.info.identity.type" class="w-full input__select">
                    <option value="pin">Personal identification number</option>

                    <option value="passport">Passport number</option>
                  </select>
                </div>
              </div>

              <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
                <InputText
                  ref="input"
                  label="Number"
                  :value="streamerInfo.info.identity.value"
                  @onChange="setIdentityValue"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <Title class="items-center mt-4 mb-4 text-center">
              <h3>
                Please send an invoice to <a href="jg@adlab.gg" class="underline">jg@adlab.gg</a> when you wish to
                withdraw.
              </h3>
            </Title>
          </div>
        </div>

        <div v-if="settingIndex === 2">
          <Title align="center" class="mt-4" size="medium" title="Social Media" />

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText ref="input" label="Twitch" :value="streamerInfo.social.twitch.handle" :disabled="true" />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Youtube"
              :value="streamerInfo.social.youtube.handle"
              index="youtube"
              @onChange="setHandle"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Twitter"
              :value="streamerInfo.social.twitter.handle"
              index="twitter"
              @onChange="setHandle"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Facebook"
              :value="streamerInfo.social.facebook.handle"
              index="facebook"
              @onChange="setHandle"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Instagram"
              :value="streamerInfo.social.instagram.handle"
              index="instagram"
              @onChange="setHandle"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Tiktok"
              :value="streamerInfo.social.tiktok.handle"
              index="tiktok"
              @onChange="setHandle"
            />
          </div>

          <div class="flex pt-4 pr-6 pb-4 pl-6 w-full">
            <InputText
              ref="input"
              label="Trovo"
              :value="streamerInfo.social.trovo.handle"
              index="trovo"
              @onChange="setHandle"
            />
          </div>
        </div>

        <div v-if="settingIndex === 3">
          <div v-if="organizationInvites && organizationInvites.length">
            <Title align="center" class="mt-4 mb-4" title="Organization Invites" size="medium" />

            <div>
              <div class="flex p-4 mb-16 w-full">
                <List
                  class="list--campaigns"
                  :sort="[
                    {
                      label: 'Organization',
                      prop: 'date',
                      active: true,
                      emit: 'sortByHandle',
                    },
                    {
                      label: 'Status',
                      prop: 'streamerAmount',
                      align: 'center',
                      emit: 'sortByViews',
                    },
                    {
                      label: 'Auto-accept campaigns',
                      prop: 'date',
                      align: 'center',
                      emit: 'sortByCTR',
                    },
                    {
                      label: 'Invite date',
                      prop: 'date',
                      align: 'center',
                      emit: 'sortByClicks',
                    },

                    {
                      label: '',
                      prop: '',
                    },
                  ]"
                >
                  <li v-for="(invite, inviteIndex) of organizationInvites" :key="inviteIndex" class="list__item">
                    <div class="list__item-img">
                      <img
                        v-if="invite.orgId && invite.orgId.logo"
                        :src="invite.orgId.logo"
                        :alt="`${invite.orgId.title} profile image`"
                      />

                      <Icon v-else icon="no-image" />
                    </div>

                    <div class="list__item-content">
                      <div class="list__item-col" data-col="1">
                        <Button
                          class="flex mb-2 text-base font-medium button"
                          kind="link"
                          :label="invite.orgId.title"
                        />
                      </div>

                      <div class="items-center list__item-col" data-col="2">
                        <Button
                          class="mb-2 text-sm font-medium button ellipsis"
                          kind="link"
                          :label="
                            invite.accepted === true ? 'Accepted' : invite.accepted === false ? 'Declined' : 'Pending'
                          "
                        />
                      </div>

                      <div class="items-center list__item-col" data-col="3">
                        <Button
                          class="mb-2 text-sm font-medium capitalize button ellipsis"
                          kind="link"
                          :label="invite.autoAcceptCampaigns"
                          :capitalize="true"
                        />
                      </div>

                      <div class="items-center list__item-col" data-col="4">
                        <Button
                          class="mb-2 text-sm font-medium button ellipsis"
                          kind="link"
                          :label="new Date(invite.createdDate).toLocaleDateString()"
                        />
                      </div>

                      <div class="list__item-col" data-col="0">
                        <Dropdown
                          :position="['right']"
                          :items="[
                            {
                              index: invite._id,
                              id: invite.orgId,
                              label: 'Accept',
                            },
                            {
                              index: invite._id,
                              id: invite.orgId,
                              label: 'Decline',
                            },
                          ]"
                          :is-show-more="true"
                          @change="setInviteStatus"
                        />
                      </div>
                    </div>
                  </li>
                </List>
              </div>
            </div>
          </div>

          <div>
            <Title align="center" class="mt-4 mb-4" title="Joined Organizations" size="medium" />

            <div v-if="showOrganizations">
              <div class="flex p-4 mb-16 w-full">
                <List
                  class="list--campaigns"
                  :sort="[
                    {
                      label: 'Organization',
                      prop: 'date',
                      active: true,
                      emit: 'sortByHandle',
                    },
                    {
                      label: 'Auto-accept campaigns',
                      prop: 'date',
                      align: 'center',
                      emit: 'sortByCTR',
                    },
                    {
                      label: 'Joined date',
                      prop: 'date',
                      align: 'center',
                      emit: 'sortByClicks',
                    },
                    {
                      label: '',
                      prop: '',
                    },
                  ]"
                >
                  <li
                    v-for="(organization, organizationIndex) of getContentCreatorOrganizations"
                    :key="organizationIndex"
                    class="list__item"
                  >
                    <div class="list__item-img">
                      <img
                        v-if="organization.orgId && organization.orgId.logo"
                        :src="organization.orgId.logo"
                        :alt="`${organization.orgId.title} profile image`"
                      />

                      <Icon v-else icon="no-image" />
                    </div>

                    <div class="list__item-content">
                      <div class="list__item-col" data-col="1">
                        <Button
                          class="flex mb-2 text-sm font-medium button"
                          kind="link"
                          :label="organization.orgId.title"
                        />
                      </div>

                      <div class="items-center list__item-col" data-col="2">
                        <Button
                          class="mb-2 text-sm font-medium button ellipsis"
                          :capitalize="true"
                          kind="link"
                          :label="organization.autoAcceptCampaigns"
                        />
                      </div>

                      <div class="items-center list__item-col" data-col="3">
                        <Button
                          class="mb-2 text-sm font-medium button ellipsis"
                          kind="link"
                          :label="new Date(organization.joinedDate).toLocaleDateString()"
                        />
                      </div>

                      <div class="list__item-col" data-col="0">
                        <Dropdown
                          :position="['right']"
                          :items="[
                            {
                              index: organization.orgId._id,
                              id: ` `,
                              label: 'Leave organization',
                            },
                          ]"
                          :is-show-more="true"
                        />
                      </div>
                    </div>
                  </li>
                </List>
              </div>
            </div>

            <p v-else class="text-center">You are not part of any organizations</p>
          </div>
        </div>

        <div class="flex justify-center items-center mt-8 mb-6 w-full">
          <Button kind="primary" label="Save settings" @clicked="saveSettings" />
        </div>
      </Tile>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Icon, Button, Title } from "cavea-design-system";
import log from "@/lib/log";
import countryCode from "@/lib/helpers/countryCodes";

export default {
  name: "StreamerSettings",

  metaInfo: {
    title: "Settings",
  },

  components: {
    Icon,
    List: () => import("@/modules/List"),
    Tile: () => import("@/components/Tile"),
    Title,
    Button,
    Dropdown: () => import("@/components/Dropdown"),
    InputText: () => import("@/components/inputs/InputText"),
    AutoComplete: () => import("@/components/inputs/AutoComplete"),
  },

  data() {
    return {
      streamerInfo: null,
      languageList: [],
      settingIndex: 0,
      billingType: 0,
      organizationInvites: [],
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getContentCreatorOrganizations"]),

    countriesList() {
      return countryCode("all").map((c) => c.country);
    },

    showOrganizations() {
      return this.getContentCreatorOrganizations && this.getContentCreatorOrganizations?.length > 0;
    },
  },

  watch: {
    streamerInfo: {
      handler() {
        if (this.streamerInfo?._id) {
          this.fetchOrganizationInvites();
        }
      },
    },
  },

  created() {
    this.streamerInfo = this.getUserInfo;

    this.fetchLanguageList();

    const params = new URLSearchParams(window.location.search);
    log("params", params);
    const page = params?.get("page");
    if (page && parseInt(page, 10)) {
      this.settingIndex = parseInt(page, 10);
    }

    if (this.streamerInfo?._id) {
      this.fetchOrganizationInvites();
    }
  },

  methods: {
    ...mapActions(["setUserInfo", "fetchContentCreatorOrganizations"]),

    async fetchLanguageList() {
      log("fetchLanguageList");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/get-languages`;

      this.languageList = await axios
        .get(URL)
        .then((res) => res?.data?.languages)
        .catch((err) => {
          console.error("error getting languages", err);
          return [];
        });
    },

    async saveSettings() {
      log("saveSettings");

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/content-creator/settings`;

      const response = await axios
        .post(URL, this.streamerInfo)
        .then((res) => {
          this.$swal({
            icon: "success",
            title: `Updated settings successfully`,
            showConfirmButton: false,
            timer: 1000,
          });

          return res.data;
        })
        .catch((err) => {
          console.error("saveSettings", err);

          this.$swal({
            icon: "error",
            title: `Something went wrong`,
            text: "Please try again later",
            showConfirmButton: false,
            timer: 1000,
          });

          return err?.response?.data;
        });

      if (response?.contentCreator) {
        this.setUserInfo(response.contentCreator);
      }

      this.$emit("closeSettingsModal");
    },

    setFullName(name) {
      log(`setFullName ${name}`);
      this.streamerInfo.fullname = name.trim();
    },

    setEmail(email) {
      log(`setEmail ${email}`);

      this.streamerInfo.email = email.trim();
    },

    setHandle(value, index) {
      log(`setHandle ${value} ${index}`);

      if (value) {
        this.streamerInfo.social[index].handle =
          index.toLowerCase() === "youtube" ? value.trim() : value.trim().toLowerCase();
      } else {
        this.streamerInfo.social[index].handle = value;
      }
    },

    /**
     * @param {string} value
     */
    setCountry(value) {
      log(`setCountry ${value}`);

      this.streamerInfo.info.country = value;
    },

    /**
     * @param {string} value
     */
    setBroadcastingLanguage(value) {
      log(`setBroadcastingLanguage ${value}`);

      this.streamerInfo.info.broadcastingLanguage = value;
    },

    async fetchOrganizationInvites() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/organization-invites/${this.getUserInfo._id}`;

      this.organizationInvites = await axios
        .get(URL)
        .then((res) => res.data.invites)
        .catch((error) => {
          console.error("fetchOrganizationInvites error", error);
          return [];
        });
    },

    async setInviteStatus(e) {
      log({ msg: "setInviteStatus", e });

      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/decide-organization-invite`;

      const requestBody = {
        inviteId: e.item.index,
      };

      if (e.item.label.toLowerCase() === "accept") {
        const confirmation = await this.$swal({
          title: `Are you sure you want to join ${e.item.id.title}?`,
          text: `Auto accept is enabled, so ${e.item.id.title} can automatically join campaigns on your behalf!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: "Yes!",
        });

        if (confirmation?.isConfirmed) {
          log({ msg: "accepted", confirmation });
          requestBody.accepted = true;

          await axios
            .put(URL, requestBody)
            .then((res) => res.data)
            .catch((error) => {
              console.error("Error setting invite request status ", error);
              return error;
            });
        }
      } else if (e.item.label.toLowerCase() === "decline") {
        const confirmation = await this.$swal({
          title: `Are you sure you want to decline ${e.item.id.title}?`,
          text: `${e.item.id.title} will have to invite you again, if you change your mind!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#c13859",
          confirmButtonText: "Yes!",
        });

        if (confirmation?.isConfirmed) {
          log({ msg: "declined", confirmation });
          requestBody.accepted = false;

          await axios
            .put(URL, requestBody)
            .then((res) => res.data)
            .catch((error) => {
              console.error("Error setting invite request status ", error);
              return error;
            });
        }
      }

      this.fetchOrganizationInvites();

      this.fetchContentCreatorOrganizations();
    },

    setAddress(address) {
      log(`setAddress ${address}`);
      this.streamerInfo.info.address = address.trim();
    },

    setPostalCode(postalCode) {
      log(`setPostalCode ${postalCode}`);
      this.streamerInfo.info.postalCode = postalCode.trim();
    },

    setCity(city) {
      log(`setCity ${city}`);
      this.streamerInfo.info.city = city.trim();
    },

    setIban(iban) {
      log(`setIban ${iban}`);
      this.streamerInfo.info.banking.iban = iban.trim();
    },

    setBic(bic) {
      log(`setBic ${bic}`);
      this.streamerInfo.info.banking.bic = bic.trim();
    },

    setIdentityValue(value) {
      log(`setIdentityValue ${value}`);
      this.streamerInfo.info.identity.value = value.trim();
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/colors";
@import "../../styles/fonts";
@import "../../styles/globals";
@import "../../styles/helpers";

input {
  display: block;
}

.list--campaigns {
  // Title
  [data-col="1"] {
    width: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  [data-col="2"],
  [data-col="3"],
  [data-col="4"],
  [data-col="5"],
  [data-col="6"] {
    width: calc(22.5% - 1.5rem);
    margin-right: 1.5rem;
    align-items: center;
  }

  // Dropdown
  [data-col="0"] {
    width: 1.25rem;
    margin: 0 1.5rem 0 1.5rem;
  }
}

.list__item {
  background-color: color(blue, darker);
}

.settings__buttons {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  width: 100%;

  button {
    width: 100%;
    @media (min-width: 1024px) {
      width: auto;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
}

.input__select {
  outline: 0;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.5rem;
  height: 2.5rem;
  background: transparent;
  border-radius: $border-radius;
  transition: var(--transition-out);
  background-color: color(white, off);
  font-family: $primary-font-stack;
  border: 1px solid color(border, base);
  color: color(blue, light);
  option {
    color: inherit;
  }
}

.active-tab > button {
  color: #34c66e !important;
}
</style>
